import { Component, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { applicantDetialsApi } from "@/providers/apis/applicant-dashboard";
import moment from "moment";
import ReviewerCertificationPartOnePreviewForm from "../reviewer-certification-part-one-preview-form/reviewer-certification-part-one-preview-form.component.vue";
import ReviewerCertificationPartTwoPreviewForm from "../reviewer-certification-part-two-preview-form/reviewer-certification-part-two-preview-form.component.vue";
import ReviewerRenewalPreview from "../reviewer-renewal-preview/reviewer-renewal-preview.component.vue";
import ReviewerStatetoStatePreview from "../reviewer-state-to-state-preview/reviewer-state-to-state-preview.component.vue";
import { applicationListData } from "@/models/user-applications/user-applications.interface";
import { utils } from "@/utils/utils";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";

@Component({
  name: "user-applications",
  components: {
    AppLoaderComponent,
    ReviewerCertificationPartOnePreviewForm,
    ReviewerCertificationPartTwoPreviewForm,
    ReviewerRenewalPreview,
    ReviewerStatetoStatePreview,
    ErrorPopupComponent,
  },
})
export default class UserApplicationsComponent extends Vue {
  public viewApplication = false;
  public toogleExpand = false;
  public isLoading = true;
  public activePreviewFormName = "";
  public applicationFieldDetials = {};
  public applicationListData: applicationListData = {} as applicationListData;
  public certificatePreviewForm: any = {};
  public label: any = {
    partOne: "Part One Application",
    partTwo: "Part Two Application",
    renewal: "Renewal Application",
    stateToState: "State to State Application",
  };
  public headrTitle: any = {
    partOne: "Certification: Part One",
    partTwo: "Certification: Part Two",
    renewal: "Renewal Application",
    stateToState: "State to State Application",
  };
  public errorMessage = "";
  public softRefresh = false;
  public updateReviewer: any;

  public created() {
    this.sldebarHide();
  }

  public sldebarHide() {
    this.isLoading = true;
    this.getUserApplicationData();
  }
  public async getUserApplicationData() {
    await applicantDetialsApi
      .getUserApplication()
      .then(async (result) => {
        const finalData: any = {};
        for (const property in result.data) {
          if (result.data[property]) {
            result.data[property].map(() => {
              finalData[property] = result.data[property].sort(
                (a: any, b: any) => {
                  a.createdAt = moment(a.createdAt).format(
                    "DD MMM YYYY HH:mm:ss:SSS"
                  );
                  b.createdAt = moment(b.createdAt).format(
                    "DD MMM YYYY HH:mm:ss:SSS"
                  );
                  return (
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                  );
                }
              );
              finalData[property].reverse();
              finalData[property]?.map((item: any) => {
                item.createdAt = moment(item.createdAt).format("MM-DD-YYYY");
              });
            });
          }
        }
        this.applicationListData = finalData;
        this.isLoading = false;
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }
  public viewApplicationDetials(formName: any, data: any) {
    this.softRefresh = !this.softRefresh;
    this.activePreviewFormName = formName;
    this.viewApplication = true;
    this.certificatePreviewForm = data;
  }

  public closeSidebar() {
    this.viewApplication = false;
  }

  // This method for change status word for class

  public statusWordChange(status: string) {
    return utils.statusCaseChange(status);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
  public onDraft(key: string, value: any, index: number) {
    if (value && value[index].fillStatus === "DRAFT") {
      value[index].data.fillStatus = value[index].fillStatus;
      value[index].data.id = value[index].id;
      this.onOpenDraftForm(key, value[index].id, value[index]);
    }
  }

  public onOpenDraftForm(key: string, applicantId: any, value: any) {
    if (key === "partOne") {
      localStorage.setItem(
        "partOneCertificatePayload",
        JSON.stringify(value.data)
      );
      window.location.href = `/form/certification-part-one-form?id=${applicantId}&isDraft=true`;
    }
    if (key === "partTwo") {
      localStorage.setItem(
        "partTwoCertificationPayload",
        JSON.stringify(value.data)
      );
      window.location.href = `/form/certification-part-two-form?id=${applicantId}&isDraft=true`;
    }
    if (key === "renewal") {
      localStorage.setItem("renewalPayLoadData", JSON.stringify(value.data));
      window.location.href = `/form/renewal-application?id=${applicantId}&isDraft=true`;
    }
  }
  public getPeerUpdatedData(event: boolean) {
    this.getUserApplicationData();
    this.viewApplication = false;
    if (event) {
      this.activePreviewFormName = "";
      (this.$refs.mySidebar as HTMLFormElement).hide();
    }
  }
  public isUpdateForm(event: any) {
    this.updateReviewer = event;
    this.$nextTick(() => {
      this.$bvModal.show("update-email-center");
    });
  }
  public closePopup() {
    this.$bvModal.hide("update-email-center");
  }
}
